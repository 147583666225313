<template>
    <div class="redes" :class="{ 'text-center' : $mq === 'sm' || $mq === 'xs' }">
        <span>SIGA-ME NAS REDES</span>
        <a target="_blank" href="https://www.instagram.com/heberlima/"><i class="instagram"></i></a>
        <a target="_blank" href="https://www.facebook.com/heberaireslima"><i class="facebook"></i></a>
        <a target="_blank" href="https://www.youtube.com/c/heberaireslima"><i class="youtube"></i></a>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.redes{
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    color: #ffffff;
    font-size: 25px;
    text-align: left;
    padding-top: 32px;
    span{
    display: block;
    margin-bottom: 16px;
    }
    i{
        margin-right: 16px;
        &.instagram{
            width: 48px !important;
            height: 48px !important;
            mask-image: url('~@/assets/imgs/Instagram_black.svg') !important;
            -webkit-mask-image: url('~@/assets/imgs/Instagram_black.svg') !important;
            background-color: #ffffff;
            display: inline-block;
        }
        &.facebook{
            width: 48px !important;
            height: 48px !important;
            mask-image: url('~@/assets/imgs/Facebook_black.svg') !important;
            -webkit-mask-image: url('~@/assets/imgs/Facebook_black.svg') !important;
            background-color: #ffffff;
            display: inline-block;
        }
        &.youtube{
            width: 48px !important;
            height: 48px !important;
            mask-image: url('~@/assets/imgs/Youtube_black.svg') !important;
            -webkit-mask-image: url('~@/assets/imgs/Youtube_black.svg') !important;
            background-color: #ffffff;
            display: inline-block;
        }
    }
    i:hover{
        background-color: #f5de6b !important;
    }
}
</style>