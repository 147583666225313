<template>
  <b-row :class="{ 'mt-5 p-2' : $mq === 'sm' || $mq === 'xs' || $mq === 'md'}" align="center" align-v="center" class="page">
    <b-col cols="12" sm="12" md="12" lg="5" xl="5">
      <b-img src="img/HEBER.png" fluid class="heber-photo-mobile" v-if="($mq === 'sm' || $mq === 'xs') && !imgWebp"/>
      <b-img src="img/HEBER.webp" fluid class="heber-photo-mobile" v-if="($mq === 'sm' || $mq === 'xs') && imgWebp"/>
      <logo-heber-lima/>
      <news/>
      <social-networks/>
    </b-col>
    <b-col cols="12" sm="12" md="12" lg="6" xl="6" :class="{ 'mt-3' : $mq === 'sm' || $mq === 'xs' || $mq === 'md'}" class="mb-5">
      <b-img v-if="$mq !== 'sm' && $mq !== 'xs' && !imgWebp" src="img/HEBER.png" fluid class="heber-photo"/>
      <b-img v-if="$mq !== 'sm' && $mq !== 'xs' && imgWebp" src="img/HEBER.webp" fluid class="heber-photo"/>
    </b-col>
  </b-row>
</template>

<script>
import LogoHeberLima from '../components/LogoHeberLima'
import SocialNetworks from '../components/SocialNetworks'
import News from '../components/News'
export default {
  name: 'home',
  components: {
    News,
    SocialNetworks,
    LogoHeberLima
  },
  computed:{
    imgWebp(){
      return this.canUseWebP()
    }
  },
  methods: {
    canUseWebP() {
      var elem = document.createElement('canvas');
      if (!!(elem.getContext && elem.getContext('2d'))) return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
      return false;
    },
  },
  created(){
    window.scrollTo({top: 0, behavior: 'smooth'});
    document.body.style.backgroundColor = "#034689"
    document.body.style.backgroundImage = this.$mq !== 'xs' 
                                          && this.$mq !== 'sm' 
                                          && this.$mq !== 'md' ? 
                                            !this.imgWebp ? "url('/img/BG.jpg')" : "url('/img/BG.webp')" : 
                                            !this.imgWebp ? "url('/img/sm/BG.jpg')" : "url('/img/sm/BG.webp')"
  }
}
</script>
<style lang="scss" scoped>
@font-face {
  font-family: "Front Page Neue";
  src: url("~@/assets/fonts/front-page-neue/6dc0bf90067240ac1860b77131d8144d.eot"); /* IE9*/
  src: url("~@/assets/fonts/front-page-neue/6dc0bf90067240ac1860b77131d8144d.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("~@/assets/fonts/front-page-neue/6dc0bf90067240ac1860b77131d8144d.woff2") format("woff2"), /* chrome firefox */
  url("~@/assets/fonts/front-page-neue/6dc0bf90067240ac1860b77131d8144d.woff") format("woff"), /* chrome firefox */
  url("~@/assets/fonts/front-page-neue/6dc0bf90067240ac1860b77131d8144d.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
  url("~@/assets/fonts/front-page-neue/6dc0bf90067240ac1860b77131d8144d.svg") format("svg"); /* iOS 4.1- */
  font-display: swap !important;
}
img{
  max-width: 100% !important;
  height: auto !important;
}
.page{
  margin-top: -60px;
}
.heber-photo-mobile{
  margin-top: -70px;
  margin-bottom: 20px;
}
</style>