<template>
  <div class="news">
    <span class="titulo-small" :class="{ 'text-center' : $mq === 'sm' || $mq === 'xs' }">CADASTRE-SE E RECEBA</span>
    <span :class="{ 'text-center titulo-movel' : $mq === 'sm' || $mq === 'xs', 'titulo' : $mq !== 'sm' && $mq !== 'xs' }">INFORMATIVOS E EBOOKS</span>
    <div class="input-email" :class="{ 'input-center' : $mq === 'sm' || $mq === 'xs' }">
      <input type="email" :disabled="loadingAdd" placeholder="INFORME SEU E-MAIL" v-model.trim="form.email" @keyup.enter="Register">
      <button @click="Register" :disabled="loadingAdd" :class="{ 'disabled' : loadingAdd }">{{ loadingAdd ? 'AGUARDE ...' : 'CADASTRAR'}}</button>
    </div>
  </div>
</template>
<script>
import { collection, doc, setDoc, serverTimestamp } from "firebase/firestore";

export default {
  data(){
    return {
      form:{
        email: null,
      },
      loadingAdd: false,
      Toast: this.$swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', this.$swal.stopTimer)
          toast.addEventListener('mouseleave', this.$swal.resumeTimer)
        }
      })
    }
  },
  methods:{
    async Register(){
      if(!this.loadingAdd){
        let form = this.form;
        let emailCheck = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i;
        if(!form.email || form.email && form.email.length <= 0) this.ToastError('Preencha o campo E-mail')
        else if(!emailCheck.test(form.email)) this.ToastError('E-mail inválido')
        else {
          this.loadingAdd = true;
          let collectionRef = collection(this.$db, "Newsletter");
          let docRef = doc(collectionRef, form.email);
          setDoc(docRef,{ email: form.email, data_cadastro: serverTimestamp() })
            .then(async()=> {
              await this.axios
              .post("https://andlima.com.br/hl/api?acao=news", this.form)
              .then(()=>{
                this.loadingAdd = false
                this.MessageSuccess()
                this.ClearFields();
              })
            } )
            .catch((err)=> {
              this.loadingAdd = false
              this.ToastError('Este e-mail já foi cadastrado')
            })
        }
      }
    },
    ClearFields(){
      this.form = {
        email: null,
      };
    },
    ToastError(msg) {
      this.Toast.fire({
        icon: 'error',
        title: msg,
      })
    },
    MessageSuccess() {
      this.$swal.fire({
        icon: 'success',
        title: 'Confirmação',
        text: 'E-mail cadastrado com sucesso!',
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.news{
  text-align: left;
  padding-top: 32px;
  .titulo-small{
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 20px;
    display: block;
    color:#ffffff;
    text-align: left;
  }
   .titulo-movel{
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 20px;
    line-height: 28px;
    display: block;
    color:#ffffff;
    text-align: left;
  }
  .titulo{
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 26px;
    line-height: 28px;
    display: block;
    color:#ffffff;
    text-align: left;
  }
  .input-email{
    background-color: rgba(255, 255, 255, 0.77);
    border-radius: 0px 16px  16px  16px;
    max-width: fit-content;
    height: 45px;
    margin-top: 8px;
    padding: 5px;
    display: flex;
    input{
      display: inline-flex;
      flex: 1;
      border: none !important;
      background-color: transparent !important;
      padding-left: 8px !important;
      padding-right: 8px !important;
      font-family: 'Montserrat', sans-serif;
      font-weight: 400;
      color: #000000;
      font-size: 1.8vh;
      outline: none !important;
      box-shadow: none !important;
    }
    button{
      display: inline-flex;
      align-items: center;
      border-radius: 0px 10px  10px  10px;
      background-color: #0581b6;
      color: #ffffff;
      font-family: 'Montserrat', sans-serif;
      font-weight: 800;
      font-size: 14px;
      padding: 10px;
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
      justify-content: center;
      cursor: pointer;
      &.disabled{
        background-color: #333333;
        color: #ffffff;
        cursor: not-allowed;
      }
    }
    button:hover{
      background-color: #0098DA;
      &.disabled{
        background-color: #333333;
        color: #ffffff;
        cursor: not-allowed;
      }
    }
  }
  .input-center{
    margin-left: auto;
    margin-right: auto;
  }
}
</style>