<template>
   <b-row>
      <div class="logo-heberlima" :class="{ 'logo-heberlima-md' : $mq === 'md', 'text-center': $mq === 'sm' || $mq === 'xs' }">
         HÉBER LIMA
      </div>
      <div class="especialista" :class="{ 'especialista-md' : $mq === 'md', 'text-center': $mq === 'sm' || $mq === 'xs' }">
         ESPECIALISTA EM DIREITO ELEITORAL
      </div>
   </b-row>
</template>

<script>
export default {

}
</script>

<style scoped>
.logo-heberlima{
  font-family: "Front Page Neue";
  color: #ffffff;
  font-size: 11vh;
  line-height: 7vh;
  margin-right: 30px;
  text-align: left;
}
.logo-heberlima-md{
   margin-top: 30px;
   font-size: 10.8vh;
}
.especialista{
  font-family: "Front Page Neue";
  color: #F0CC1C;
  font-size: 3.5vh;
  width: 500px;
  text-align: left;
}
.especialista-md{
   font-size: 3.4vh;
}
</style>